@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fontsource/roboto/100.css';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

@layer base {
  :root {
    /* Colors extended at tailwind.config.js */
    --color-primary: #002859;
    --color-primary-50: #ccd7e5;
    --color-primary-100: #b2c3d7;
    --color-primary-200: #99afca;
    --color-primary-300: #00377b;
    --color-primary-300-transparent: rgba(0, 55, 123, 0.5);

    --color-secondary: #1c1c1c;
    --color-secondary-50: #f6f6f6;
    --color-secondary-100: #e4e7ec;
    --color-secondary-200: #d0d5dd;
    --color-secondary-300: #bdbcbc;
    --color-secondary-400: #5b658a;
    --color-secondary-600: #f2f5f8;
    --color-secondary-700: #d0d5dd;

    --color-success: #13ab52;
    --color-success-transparent: rgba(19, 171, 82, 0.5);
    --color-danger: #dc0707;
    --color-danger-transparent: rgba(220, 7, 7, 0.5);
    --color-info: var(--color-primary-100);
  }

  .react-tooltip {
    @apply border border-secondary-300;
  }

  ::backdrop {
    @apply bg-modalBackdrop;
  }

  html {
    @apply scroll-smooth;
  }

  body {
    @apply leading-7 text-base-color bg-white;
  }

  body[data-menu-extended] .js-header-search-icon,
  body[data-menu-extended] .js-header-avatar-icon {
    @apply pointer-events-none;
  }

  body[data-menu-extended] .js-header-search-icon + div,
  body[data-menu-extended] .js-header-avatar-icon + div {
    @apply opacity-0 hidden pointer-events-none;
  }

  .text-base-color {
    color: var(--color-secondary);
  }

  .text-primary-color {
    color: var(--color-primary);
  }

  .text-primary-color-300 {
    color: var(--color-primary-300);
  }

  h1,
  .h1 {
    @apply text-4xl font-bold;
  }

  h2,
  .h2 {
    @apply text-2xl font-bold;
  }

  h3,
  .h3 {
    @apply text-lg font-bold;
  }
}

@layer utilities {
  /* Preload Logo */
  .pks-logo-preload {
    background-image: url('./assets/images/logos/logo.png');
    display: none;
  }

  .pks-bg-logo {
    background-image: url('./assets/images/logos/logo-white.png');
  }

  /* Container */
  .pks-container {
    @apply container pr-4 pl-4 lg:max-w-screen-xl lg:pr-8 lg:pl-8 xl:pr-19 xl:pl-19;
  }

  .pks-loader-img {
    background-image: url('./assets/images/logos/logo-white.png');
  }

  /* Layout */
  .pks-layout-col,
  [class^='pks-layout-col-'] {
    @apply flex flex-col;
  }

  .pks-layout-col {
    @apply gap-2;
  }

  .pks-layout-col-sm {
    @apply gap-3;
  }

  .pks-layout-col-md {
    @apply gap-4;
  }

  .pks-layout-col-xl {
    @apply gap-8;
  }

  /* Card */
  .pks-card,
  .pks-card-bg {
    @apply shadow-card border border-secondary-200;
  }

  .pks-card-bg-active {
    @apply border-primary-300;
  }

  .pks-card-hover,
  .pks-card-bg-hover {
    @apply hover:border-primary-300;
  }

  .pks-card-bg {
    @apply bg-secondary-400;
  }

  .pks-card-bg-active {
    @apply bg-primary-300;
  }

  .pks-card-bg-hover {
    @apply hover:bg-primary-300;
  }

  /* Divider */
  .pks-divider-dashed {
    @apply relative overflow-hidden h-4 mb-4 before:absolute before:top-0 before:left-[-10px] before:right-[-10px] before:bottom-0 before:pointer-events-none before:content-none;
  }

  .pks-divider-dashed.pks-divider-dashed-narrow {
    @apply h-2 mb-2;
  }

  .pks-divider-dashed::before {
    border-bottom: 1px solid var(--color-secondary);
    mask-image: linear-gradient(
        to right,
        var(--color-secondary) 50%,
        transparent 50%
      ),
      linear-gradient(to bottom, var(--color-secondary) 50%, transparent 50%);
    mask-size: 40px 1px;
    content: '';
  }

  /* Input */
  .pks-input {
    @apply appearance-none outline-none block w-full h-[50px] px-4 rounded-lg bg-white border placeholder-secondary-300 resize-none;
  }

  .pks-input.pks-input-fake {
    @apply flex items-center overflow-hidden;
  }

  .pks-input-disabled {
    @apply appearance-none outline-none block w-full h-[50px] px-4 rounded-lg bg-secondary-100 placeholder-secondary-300 resize-none border-secondary-300 text-secondary-300;
  }

  .pks-input.pks-input-icon-left {
    @apply pl-11;
  }

  .pks-input[type='search'] {
    @apply pr-10;
  }

  .pks-input.pks-input-h-full {
    @apply h-full;
  }

  .pks-input.pks-input-px-0 {
    @apply px-0;
  }

  .pks-input-initial {
    @apply border-secondary-300 hover:border-primary-300 focus:border-primary-300-transparent focus:shadow-[0_0_0_2px_var(--color-primary-300-transparent)];
  }

  .pks-input-success {
    @apply border-success hover:border-success focus:border-success-transparent focus:shadow-[0_0_0_2px_var(--color-success-transparent)];
  }

  .pks-input-error {
    @apply border-danger hover:border-danger focus:border-danger-transparent focus:shadow-[0_0_0_2px_var(--color-danger-transparent)];
  }

  /* Checkbox Input */
  .pks-cbx {
    @apply leading-3;
  }

  .pks-cbx .cbx {
    @apply outline-none flex items-center overflow-hidden rounded-md /* py-1.5 */;
  }

  .pks-cbx:not(.pks-cbx-fake) .cbx {
    @apply px-0 cursor-pointer select-none transition-all duration-150 ease-linear border border-transparent /* focus:border focus:border-primary-300 */;
  }

  .pks-cbx.pks-cbx-fake .cbx {
    @apply px-0;
  }

  .pks-cbx .cbx:not(:last-child) {
    @apply mr-1.5;
  }

  .pks-cbx:not(.pks-cbx-fake) .cbx:hover {
    @apply bg-[var(--color-secondary-50)];
  }

  .pks-cbx .cbx span {
    @apply float-left align-middle transform translate-x-0 translate-y-0;
  }

  .pks-cbx .cbx span:first-child {
    @apply relative w-[18px] h-[18px] rounded transform scale-100 border border-[#cccfdb] transition-all duration-150 ease-linear shadow-[0_1px_1px_rgba(0,16,75,0.05)];
  }

  .pks-cbx .cbx span:first-child svg {
    @apply absolute top-[3px] left-0.5 fill-none stroke-white stroke-2 transition-all duration-150 ease-linear delay-[0.1s] transform translate-x-0 translate-y-0;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
  }

  .pks-cbx .cbx span:last-child {
    @apply pl-2 leading-[18px];
  }

  .pks-cbx:not(.pks-cbx-fake) .cbx:hover span:first-child {
    @apply border-[var(--color-primary)];
  }

  .pks-cbx .inp-cbx {
    @apply absolute invisible;
  }

  .pks-cbx .inp-cbx:checked + .cbx span:first-child,
  .pks-cbx.checked .cbx span:first-child {
    @apply bg-[var(--color-primary)] border-[var(--color-primary)];
  }

  .pks-cbx .inp-cbx:checked + .cbx span:first-child svg,
  .pks-cbx.checked .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }

  .pks-cbx .inline-svg {
    @apply absolute w-0 h-0 pointer-events-none select-none;
  }

  /* CMS */
  .pks-cms ul,
  .pks-cms ol {
    @apply ml-4 pl-4 mt-2 mb-2 space-y-2;
  }

  .pks-cms ul {
    @apply list-disc;
  }

  .pks-cms ol {
    @apply list-decimal;
  }

  .pks-cms ol li[data-list='bullet'] {
    @apply list-disc;
  }

  .pks-cms blockquote {
    @apply mx-4 my-2;
  }

  .pks-cms a {
    @apply text-base-color underline hover:text-primary-color-300;
  }

  .pks-cms h2,
  .pks-cms h3,
  .pks-cms p:not(:last-of-type) {
    @apply mb-2;
  }

  /* PKS Quill */
  .pks-quill {
    display: flex;
    flex-direction: column;
  }

  .pks-quill.ql-container {
    display: flex;
    flex-direction: column;
  }
  .pks-quill.ql-container .ql-editor {
    flex-grow: 1;
  }

  .pks-quill.ql-container .ql-editor[contenteditable='false'] {
    background-color: var(--color-secondary-100);
    color: var(--color-secondary-300);
  }
}

.scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 10px;
  cursor: pointer;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c5c3c3ff;
  border-radius: 10px;
  cursor: pointer;
}
